<template>
    <section class="js-section js-ui-switch c-NextSection u-relative u-fit-w u-bg--black u-color--white u-overflow-h" v-bind:class="{ 'is-active': active }" >
        <cursor-trigger v-bind:name="'view'" class="u-relative u-pos-tl u-fit u-force-3d">
            <parallax-object v-bind:ratio="-0.8" class="u-relative u-pos-tl u-fit ">
                <a v-bind:href="url" v-on:click.prevent="onClick" v-on:mouseenter="onEnter" v-on:mouseleave="onLeave" class="c-Container t-link u-block u-fit u-flex u-align-items-c ">
                    <div class="c-NextSection-content u-relative u-fit-w u-flex u-flex-flow-row-wrap">
                        <div class="u-w3of16 u-offset-l-w2of16 | u-w5of8@sm u-offset-l-w1of8@sm">
                            <p class="t-text--xxs u-uppercase u-font-bold">Next<br>{{ label }}</p>
                        </div>

                        <div class="u-w6of16 u-offset-l-w1of16 | u-w5of8@sm u-offset-l-w1of8@sm u-marg-t-lg@sm">
                            <p class="t-h2" v-html="title"></p>
                        </div>
                    </div>
                </a>
            </parallax-object>
        </cursor-trigger>
    </section>
</template>

<script>
    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';

    export default {
        name: 'NextSection',

        components: {
            CursorTrigger,
            ParallaxObject,
        },

        props: {
            label: { type: String, default: '' },
            title: { type: String, default: '' },
            url: { type: String, default: '/' },
        },

        data() {
            return {
                active: false,
            };
        },

        methods: {
            onEnter() {
                this.timer = setTimeout(() => {
                    this.active = true;

                    this.$nextTick(() => {
                        this.$router.push(this.url);
                    });
                }, 2000);
            },

            onLeave() {
                clearTimeout(this.timer);
            },

            onClick() {
                this.active = true;

                clearTimeout(this.timerClick);
                clearTimeout(this.timer);
                this.timerClick = setTimeout(() => {
                    this.$router.push(this.url);
                }, 400);
            },
        },
    }
</script>

<style lang="stylus">
    .c-NextSection {
        &:before {
            content ""
            position absolute
            top 0
            left 0
            width 100%
            height 100%

            background $colors['blue'];

            transform-origin bottom
            transform scaleY(0) translateZ(0)
            will-change transform
            transition: transform 0.4s linear
        }

        &:hover {
            &:before {
                transform scaleY(1) translateZ(0)
                transition transform 2s linear

                @media $breakpoints.sm {
                    transform scaleY(0) translateZ(0)
                }
            }
        }

        &.is-active, &.is-active:hover {
             &:before {
                transform scaleY(0.999) translateZ(0) !important
                transition: transform 0.4s $ease-out-quart !important;
            }
        }

        &-content {
            padding-top  220px
            padding-bottom 215px

            @media $breakpoints.sm {
                padding-top 145px
                padding-bottom 140px
            }
        }
    }
</style>